<template>
  <div class="box box--mapBlock">
    <div class="box__inner">
      <div class="mapBlock js-tabBox mapBlock--results mapBlock--search">
        <div class="mapBlock__header">
          <div class="mapBlock__header-top">
            <div class="mapBlock__header-inner">
              <router-link class="mapBlock__back" to="map-search">
                <span class="icon">
                  <SvgIcon name="left-arrow"/>
                </span>
                <span class="text">Back to map</span>
              </router-link>
              <div class="mapBlock__info mapBlock__info--short">
                <div class="mapBlock__info-left">
                  <div class="mapBlock__info-title">Search:</div>
                  <router-link class="mapBlock__info-link" to="map-search">edit search</router-link>
                </div>
                <div class="mapBlock__info-right">
                  <div class="mapBlock__info-list">
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--route" style="align-items: center;">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="placeholder"/>
                      </div>
                      <div class="mapBlock__info-list-text" style="align-items: center;">
                        <span class="text text--departure">{{featuredFlight.departure}}</span>
                        <span class="arrow">
                          <SvgIcon name="long-left-arrow"/>
                        </span>
                        <span class="text text--arrival">{{featuredFlight.arrival}}</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--way">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="airplane"/>
                      </div>
                      <div class="mapBlock__info-list-text">One way</div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--date">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="calendar"/>
                      </div>
                      <div class="mapBlock__info-list-text">
                        <span class="text text--desktop">{{setDate(featuredFlight.departureDate)}}</span>
                        <span class="text text--mobile">{{setDate(featuredFlight.departureDate)}}</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--count">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="user"/>
                      </div>
                      <div class="mapBlock__info-list-text">{{featuredFlight.paxFromSearch}}</div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--type">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="charter-flight"/>
                      </div>
                      <div class="mapBlock__info-list-text">Charter Aircraft Space</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mapBlock__header-bottom js-tabNav-wrap">
            <div class="mapBlock__tab-nav"></div>
          </div>
        </div>
        <div class="mapBlock__content">
          <router-link class="mapBlock__sidebar-title mapBlock__sidebar-title--search-results" to="map-search">
            <div class="mapBlock__sidebar-title-inner">
              <span class="text">Map</span>
              <span class="icon">
                <SvgIcon name="down-arrow"/>
              </span>
            </div>
          </router-link>
          <div class="mapBlock__sidebar-content mapBlock__sidebar-content--search-results">
            <div class="mapBlock__tab-content">
              <div class="mapBlock__tab-content-item js-tabContentItem" style="display: block">
                <div class="mapBlock__tab-content-inner">
                  <div class="mapBlock__results-list">
                    <div
                    class="mapBlock__results-list-item js-sidebarListItem"
                    data-href="#bookRoutesPopup"
                    @mouseenter="openContent"
                    @mouseleave="closeContent"
                  >
                    <div class="mapBlock__results-list-item-inner">
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--photo">
                        <div
                          class="mapBlock__results-list-item-img"
                          :style="{backgroundImage: `url(${featuredFlight.defaultImageUrl})`}"
                          @click="showAircraftSearchGallery(featuredFlight)"
                        >
                          <span class="mapBlock__results-list-item-img-back">
                            <SvgIcon name="zoom"/>
                          </span>
                        </div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--locations">
                        <ul class="mapBlock__results-list-item-route">
                          <li class="mapBlock__results-list-item-route-locationOne">
                            <div class="mapBlock__results-list-item-title mapBlock__results-list-item-title--hideForMobile">Out</div>
                            <div class="mapBlock__results-list-item-text">{{featuredFlight.departure}}</div>
                          </li>
                          <li class="mapBlock__results-list-item-route-arrow">
                            <div class="mapBlock__results-list-item-arrow">
                              <SvgIcon name="long-left-arrow"/>
                            </div>
                          </li>
                          <li class="mapBlock__results-list-item-route-locationTwo">
                            <div class="mapBlock__results-list-item-title mapBlock__results-list-item-title--hideForMobile">In</div>
                            <div class="mapBlock__results-list-item-text">{{featuredFlight.arrival}}</div>
                          </li>
                        </ul>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--aircraftModel">
                        <div class="mapBlock__results-list-item-title">Aircraft model</div>
                        <div class="mapBlock__results-list-item-text">{{featuredFlight.aircraftModel}}</div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--aircraftType">
                        <div class="mapBlock__results-list-item-title">Aicraft type</div>
                        <div class="mapBlock__results-list-item-text">{{featuredFlight.aircraftType}}</div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--safety">
                        <div class="mapBlock__results-list-item-title">Safety rating</div>
                        <div class="mapBlock__results-list-item-text">{{featuredFlight.aircraftArgusSafetyRating}}</div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--fuelStops">
                        <div class="mapBlock__results-list-item-title">Base</div>
                        <div class="mapBlock__results-list-item-text">{{featuredFlight.homeBase}}</div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--pax">
                        <div class="mapBlock__results-list-item-title">Max pax</div>
                        <div class="mapBlock__results-list-item-text">{{featuredFlight.pax}}/{{featuredFlight.aircraftPax}}</div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--tripTime">
                        <div class="mapBlock__results-list-item-title">Estimated trip time</div>
                        <div class="mapBlock__results-list-item-text">{{featuredFlight.flightDurationHours}}h {{featuredFlight.flightDurationMinutes}}min</div>
                        <div class="mapBlock__results-list-item-textTimeForMobile">{{featuredFlight.flightDurationHours}}h {{featuredFlight.flightDurationMinutes}}min</div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--price">
                        <div class="mapBlock__results-list-item-title">Estimated price</div>
                        <div class="mapBlock__results-list-item-text">
                          {{featuredFlight.totalPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}}
                        </div>
                      </div>
                      <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--buttons">
                        <!-- <button
                          class="mapBlock__results-list-item-button mapBlock__results-list-item-button--green js-popup"
                          @click="$modal.show('bookConfirmPopup')"
                          data-mod="white-popup out-close">
                          <span class="text">Book</span>
                        </button> -->
                        <button
                          class="mapBlock__results-list-item-button mapBlock__results-list-item-button--green js-popup form__button"
                          @click="createBooking(featuredFlight.aircraftAvailabilityId)"
                          data-mod="white-popup out-close"
                          >
                          <span class="text">Book</span>
                        </button>
                      </div>
                    </div>
                    <div class="mapBlock__results-list-item-detail js-sidebarListItemDetail">
                      <div class="mapBlock__results-list-item-detail-inner">
                        <div class="mapBlock__results-list-item-detail-column">
                          <div class="mapBlock__results-list-item-detail-title">Speed:</div>
                          <div class="mapBlock__results-list-item-detail-text">{{featuredFlight.aircraftSpeed}} MPH</div>
                        </div>
                        <div class="mapBlock__results-list-item-detail-column">
                          <div class="mapBlock__results-list-item-detail-title">Range:</div>
                          <div class="mapBlock__results-list-item-detail-text">{{featuredFlight.aircraftRange}} NM</div>
                        </div>
                        <div class="mapBlock__results-list-item-detail-column">
                          <div class="mapBlock__results-list-item-detail-title">Wi-fi onboard:</div>
                          <div class="mapBlock__results-list-item-detail-text">{{featuredFlight.wiFi === true ? 'Yes' : 'No'}}</div>
                        </div>
                        <div class="mapBlock__results-list-item-detail-column">
                          <div class="mapBlock__results-list-item-detail-title">YOM:</div>
                          <div class="mapBlock__results-list-item-detail-text">{{featuredFlight.manufactureYear ? featuredFlight.manufactureYear : 'N/A'}}</div>
                        </div>
                        <div class="mapBlock__results-list-item-detail-column">
                          <div class="mapBlock__results-list-item-detail-title">Refurbish:</div>
                          <div class="mapBlock__results-list-item-detail-text">{{ featuredFlight.intRefurbishYear ? featuredFlight.intRefurbishYear : 'N/A' }} (INT); {{featuredFlight.extRefurbishYear ? featuredFlight.extRefurbishYear : 'N/A'}} (EXT)</div>
                        </div>
                        <div class="mapBlock__results-list-item-detail-column">
                          <div class="mapBlock__results-list-item-detail-title">TVS:</div>
                          <div class="mapBlock__results-list-item-detail-text">{{featuredFlight.numberOfTelevision}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/manually-request" class="mapBlock__link mapBlock__link--mobile">
              <span class="icon"></span>
              <span class="text">Manual Request</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import {months} from '@/js/utils/date'
  import './SearchResults.styl'

  export default {
    name: 'FeaturedFlight',
    data: () => ({
      months: months,
    }),
    computed: {
      featuredFlight () {
        return this.$store.state.search.featuredFlightToBook
      },
    },
    methods: {
      setDate (date) {
        const result = new Date(date)
        result.setDate(result.getDate())
        return result.getDate() + ' ' + months[result.getMonth()]
      },
      openContent (event) {
        const item = event.currentTarget.querySelector('.js-sidebarListItemDetail')
        this.$root.animations.slideDown(item)
      },
      closeContent (event) {
        const item = event.currentTarget.querySelector('.js-sidebarListItemDetail')
        this.$root.animations.slideUp(item)
      },
      async showAircraftSearchGallery (item) {
        await this.$store.dispatch('setAircraftSearchGallery', item.aircraftId)
        this.$modal.show('AircraftSearchPopup')
      },
      async createBooking (id) {
        const data = this.$store.state.search.featuredFlights.find(item => item.aircraftAvailabilityId === id)
        if (data) {
          this.loading = true;
          this.id = id;
          data.pax = this.featuredFlight.paxFromSearch;
          const result = await this.$store.dispatch('createBooking', data)

          if (result) {
            this.$router.push('booking/flights')
            setTimeout(() => {
              this.loading = false;
              this.id = null;
            }, 5000);
          }
          this.$store.commit('clearTravelers')
        } else {
          console.log('Something has gone wrong when attempting to create a booking/reservation.')
        }
      },
    },
    beforeCreate () {
      if (!this.$store.state.search.featuredFlightToBook.emptyLegId) {
        this.$router.push('map-search')
      }
    },
    destroyed () {
      console.log('destroyed')
      this.$store.commit('removeFeaturedFlightToBook')
    },
    components: {
      SvgIcon,
    },
  };
</script>
